import "./adminTutorials.scss";
import "./../../utilities/script/header";
import "./../../utilities/script/footer";
import JustValidate from "just-validate";
import Swal from "sweetalert2";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import { ScrollToPlugin } from "gsap/all";
gsap.registerPlugin(ScrollTrigger, MotionPathPlugin);
gsap.registerPlugin(ScrollToPlugin);


async function copyTextToClipboard(text) {
  try {
    await navigator.clipboard.writeText(text); // Use "writeText" instead of the provided character
    console.log("Text copied to clipboard");
  } catch (err) {
    console.error("Failed to copy text:", err);
  }
}

function showCopyAlert() {
  let copied_text = document.querySelector(".copied_text");
  setTimeout(() => {
    copied_text.style.opacity = 1;
  }, 0);

  setTimeout(() => {
    copied_text.style.opacity = 0;
  }, 2000);
}

let copylink = document.getElementById("copylink");
copylink.addEventListener("click", function (e) {
  let urldata = window.location.href;
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(urldata);
    showCopyAlert();
    return;
  }
  copyTextToClipboard(urldata);
  showCopyAlert();
});

//#region toggle table of content in mobile
const screen_innerwidth = window.innerWidth;
if (screen_innerwidth < 1050) {
  let toc = document.querySelector(
    ".table-of-content h3, .table-of-content .toc-heading",
  );
  let parent_div = document.getElementById("table");
  toc.addEventListener("click", function (e) {
    parent_div.classList.toggle("active");
  });
}

//#region Sub Topic List
const GenerateUniqueID = () => {
  document
    .querySelectorAll(".blog-details h2")
    .forEach(function (element, index) {
      var uniqueID = "h2-" + (index + 1);
      element.id = uniqueID;
      console.log(index === 0);
      var textdaa = element.textContent;

      var classname = "sub-text";
      if (index === 0) {
        classname = "sub-text active-title";
      }

      document
        .querySelector(".sub-topics")
        .insertAdjacentHTML(
          "beforeend",
          `<li  class='${classname}' data-idd='${uniqueID}'>${textdaa}</a></li>`,
        );
    });
};
const BindEvent = () => {
  document.querySelectorAll(".sub-topics li").forEach(function (element) {
    element.addEventListener("click", function () {
      var obj = this;
      var datasec = obj.getAttribute("data-idd");
      obj.classList.add("active-title");
      obj.parentNode.childNodes.forEach(function (child, index) {
        if (index > 0 && child !== obj) {
          child.classList.remove("active-title");
        }
      });
      var targetElement = document.querySelector("#" + datasec);
      if (targetElement) {
        // Calculate the target scroll position with an offset of 100 pixels
        var offset = 150;
        var targetScrollPosition =
          targetElement.getBoundingClientRect().top +
          window.pageYOffset -
          offset;
        // Scroll to the calculated position
        window.scrollTo({
          top: targetScrollPosition,
          behavior: "smooth",
        });
      }
      setTimeout(() => {
        document.getElementById("table").classList.remove('active');
      }, 1000);
    });
  });
};

function bloghaddtable() {
  GenerateUniqueID();
  BindEvent();
  BindGSAP();
}

function BindGSAP() {
  let list = document.querySelector(".sub-topics");
  Array.from(document.querySelectorAll(".main-blog h2")).forEach(
    (element, index, elements) => {
      gsap.from(".main-blog h2", {
        scrollTrigger: {
          trigger: element,
          start: () => {
            return `top 155px`;
          },
          onEnter: () => {
            let id = elements[index].id;
            list
              .querySelector(".active-title")
              ?.classList.remove("active-title");
            list
              .querySelector(`.sub-topics li[data-idd="${id}"]`)
              .classList.add("active-title");
          },
          onLeaveBack: () => {
            list
              .querySelector(".active-title")
              ?.classList.remove("active-title");
            if (index === 0) return;
            let id = elements[index - 1].id;
            list
              .querySelector(`.sub-topics li[data-idd="${id}"]`)
              .classList.add("active-title");
          },
          // markers:true,
        },
      });
    },
  );
}

document.addEventListener("DOMContentLoaded", function () {
  bloghaddtable();
});

//#region  Faq Js Code

Array.from(document.querySelectorAll(".faq-questions")).map((item) => {
  item.addEventListener("click", function (e) {
    let parent_node = e.currentTarget.parentNode;
    let child_div = parent_node.querySelector(".inner_answer");

    //check faq open or not

    if (!parent_node.classList.contains("active")) {
      Array.from(document.querySelectorAll(".faq-items.active")).map((item) => {
        item.classList.remove("active");
        let child_div_loop = item.querySelector(".inner_answer");
        child_div_loop.style.height = "0px";
      });
    }

    if (parent_node.classList.contains("active")) {
      child_div.style.height = "0px";
      parent_node.classList.remove("active");
    } else {
      child_div.style.height = "auto";
      parent_node.classList.add("active");
    }
  });
});

//#endregion
// document.querySelector("#enroll-now").addEventListener("click", () => {
//   gsap.to(window, {
//     duration: 0,
//     scrollTo: { y: "iframe", offsetY: 135 },
//   });
// });

//#region EGuide Forms
if (document.getElementById("eguide-content-form")) {
  let loadingWindow2 = document.querySelector("#loading-window-popup");
  let studentEGuide = document.getElementById("eguide-content-form");
  const studentEGuideForm = new JustValidate(studentEGuide);

  studentEGuideForm
    .addField(studentEGuide.querySelector('[name="full-name"]'), [
      { rule: "required" },
      { rule: "minLength", value: 2 },
      {
        rule: "customRegexp",
        value: /^([a-zA-z,/.-]+)\s([a-zA-z,/.-]+)$/,
      },
    ])
    .addField(studentEGuide.querySelector('[name="email"]'), [
      {
        rule: "required",
        errorMessage: "This field is required",
      },
      { rule: "email" },
    ])
    .addField(studentEGuide.querySelector('[name="phone"]'), [
      {
        rule: "required",
        errorMessage: "This field is required",
      },
      {
        rule: "number",
        errorMessage: "Invalid Phone Number",
      },
      { rule: "minLength", value: 5, errorMessage: "Invalid Phone Number" },
    ])
    .addField(studentEGuide.querySelector('[name="termcheck"]'), [
      {
        rule: "required",
        errorMessage: "You must agree to continue",
      },
    ]);

  studentEGuideForm.onSuccess(async (event) => {
    try {
      const formData = new FormData(event.target);
      formData.append("website-link", window.location.href);

      let fullName = studentEGuide.querySelector('[name="full-name"]').value;

      let nameParts = fullName.split(" ");
      let firstName = nameParts[0];
      let lastName = nameParts.slice(1).join(" ");
      formData.append("first-name", firstName);
      formData.append("last-name", lastName);

      console.log(formData);


      loadingWindow2.classList.add("show");

      let response = await fetch("/wp-content/themes/s2-labs/student-form.php", {
        body: formData,
        method: "POST",
      });

      if (!response.ok) throw Error("Something Went Wrong");

      let textData = await response.text();
      if (textData.indexOf('Technical Error') > 0) {
        Swal.fire({
          title: "Error!",
          text: "Something went wrong! Please try again after sometime...",
          icon: "error",
          confirmButtonText: "Close",
        });
        window.location.href = window.location.href + '?cachebuster=' + new Date().getTime();
        return false;
      }

      Swal.fire({
        title: "Thank you!",
        text: "You will receive the email shortly.",
        icon: "success",
        confirmButtonText: "Close",
      });
    } catch {
      Swal.fire({
        title: "Error!",
        text: "Something went wrong! Please try again after sometime...",
        icon: "error",
        confirmButtonText: "Close",
      });
    } finally {
      loadingWindow2.classList.remove("show");
      studentEGuide.reset();
    }
  });
}

//#region side popup
let arrowLabel = document.querySelector('.arrow-label');
let sideArrow = arrowLabel.querySelector('.arrow');
let sidePanel = document.querySelector('.side-popup .popup-panel');
arrowLabel.addEventListener('click', () => {
  if (sideArrow.classList.contains('arrow-right')) {
    sideArrow.classList.remove('arrow-right');
    sidePanel.classList.remove('panel-width');
  }
  else {
    sideArrow.classList.add('arrow-right');
    sidePanel.classList.add('panel-width');
  }
});

setTimeout(() => {
  sideArrow.classList.add('arrow-right');
  sidePanel.classList.add('panel-width');
}, 30000);

//#endside popup

//#region Start CTA Subscribe Form

let newsLetterPopUpForm = document.querySelector('#newsletter-popup-form');
const newsLetterPopUpFormData = new JustValidate(newsLetterPopUpForm);

newsLetterPopUpFormData.addField(newsLetterPopUpForm.querySelector('[name="Email"]'), [
  { rule: "required" },
  { rule: "email" },
]);

newsLetterPopUpFormData.onSuccess(async (event) => {
  try {
    const formData = new FormData(event.target);
    formData.append("Websitelink", window.location.href);
    formData.append("leadcreate", "leadcreate");
    formData.append("lsource", "Subscribe Form");
    newsLetterPopUpForm.classList.add("send-data");
    let response = await fetch("/wp-content/themes/s2-labs/subscribeform.php", {
      body: formData,
      method: "POST",
    }).then((response) => {
      response.text().then((data) => {
        if (data.indexOf("Already") > 0) {
          Swal.fire({
            title: "Email Already Exists!",
            icon: "error",
            confirmButtonText: "OK",
          });
        } else if (data.indexOf("SUCCESS") >= 0) {
          Swal.fire({
            title: "Thanks for reaching us out!",
            text: "We've received your message, our representative will contact you soon.",
            icon: "success",
            confirmButtonText: "OK",
          });
        }
      });
    });
  } catch {
    Swal.fire({
      title: "Error!",
      text: "Something went wrong! Please try again after sometime...",
      icon: "error",
      confirmButtonText: "OK",
    });
  } finally {
    newsLetterPopUpForm.classList.remove("send-data");
    newsLetterPopUpForm.reset();
  }
});

//#endregion Start CTA Subscribe Form



//popup-js
// document.addEventListener("DOMContentLoaded", function () {
//   var popup = document.getElementById("popupGiveaway");
//   var closeBtn = document.querySelector(".close-btn-pop-US");
//   var popupImage = document.querySelector(".popup-image");
//   var isPopupShown = localStorage.getItem("WomensdayPopup7");

//   if (!isPopupShown) {
//     setTimeout(function () {
//       popup.style.display = "block";
//       localStorage.setItem("WomensdayPopup7", "true");
//     }, 15000);
//   }

//   // Close popup when clicking the close button
//   closeBtn.onclick = function () {
//     popup.style.display = "none";
//   };

//   // Close popup when clicking the popup image
//   popupImage.onclick = function () {
//     popup.style.display = "none";
//   };

//   window.onclick = function (event) {
//     if (event.target == popup.querySelector(".spacing-pop")) {
//       popup.style.display = "none";
//     }
//   };
// });